const INFO = {
	main: {
		title: "Portfolio von Moritz",
		name: "Moritz Preuß",
		email: "info@moritzpreuss.de",
		logo: "../logo1.svg",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/moleop",
		linkedin: "https://www.linkedin.com/in/moritz-preuss/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		//title: "Full-stack web and mobile app developer, and amateur astronaut.",
		//title: "Embedded Software Entwickler",
		title: "Software Entwickler für Embedded Systeme und Data Science",

		//description:
		//	"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		description:
		    "Ich bin Embedded Entwickler mit Data Science Hintergrund. Ich habe Erfahrung im Aufbau von modularen Softwarelösungen für Embedded Systeme und bin leidenschaftlich daran interessiert spannende Probleme mit qualitativ hochwertigen Code zu lösen.",
			//"Firmware Entwicklung, Software Tests und Architektur Erstellung mit konstant hoher Qualität",
	},

	about: {
		title: "Ich bin Moritz. Ich arbeite als Freelancer an verschiedenen Projekten im Bereich Embedded Software und Data Science.",
		description:
			"Ich habe in den letzten Jahren an einer Vielzahl von Projekten gearbeitet. Ich habe Spaß daran meine Fortschritte und Erfahrungen zu teilen. Wenn du Interesse an einer ein Zusammenarbeit hast oder mehr über aktuelle Projekte erfahren möchtest wirf gerne einen Blick auf meinen Social Media Accounts. Ich bin offen für Verbesserungsvorschlägen und freue mich über konstruktives Feedback."
			//"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "Mich begeistert es neue Technologien und Ideen zu entdecken und meinen Lernprozess mit Anderen zu teilen.",
		description:
			"Sammlung von Gedanken in Textform zu den Themen Programmieren, Software Architektur, Produktentwicklung und mehr."
			/*"Chronological collection of my long-form thoughts on programming, leadership, product design, and more."*/,
	},

	projects: [
		{
			title: "CMake Starter für STM32",
			description:
				"CMake Starter Projekt für STM32 Mikrocontroller mit Fokus auf modularem Software Design",
				//"Simple starter project demonstrating use of CMake for highest possible hardware independent code and modularity.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/cpp/cpp.png",
			linkText: "View Project",
			link: "https://github.com/moleop/CMakeMCUStarter",
		},

		{
			title: "Modellierung Kalibrierfunktion für Bodenproben",
			description:
				"Universitätsprojekt: Ableitung einer Kalibrierfunktion zur Bestimmung des Stickstoffgehalts von Bodenproben.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/r/r.png",
			linkText: "View Project",
			link: "https://github.com/moleop/NIR-WS1819",
		}
	],
};

export default INFO;
