import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Arbeit"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./jenoptik.png"
								alt="jenoptik"
								className="work-image"
							/>
							<div className="work-title">Jenoptik Optical Systems Gmbh</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">2021 - Present</div>
						</div>
						<div className="work">
							<img
								src="./porsche2.jpg"
								alt="porsche"
								className="work-image"
							/>
							<div className="work-title">Porsche Leipzig Gmbh</div>
							<div className="work-subtitle">
								Intern Assembly Planning
							</div>
							<div className="work-duration">2016 - 2017</div>
						</div>
						<div className="work">
							<img
								src="./innok2.png"
								alt="porsche"
								className="work-image"
							/>
							<div className="work-title">Arbeitsgruppe Innok</div>
							<div className="work-subtitle">
								Student Assistant
							</div>
							<div className="work-duration">2015 - 2018</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
