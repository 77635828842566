const SEO = [
	{
		page: "home",
		description:
			//"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
			"Firmware Entwicklung, Software Tests und Architektur Erstellung mit konstant hoher Qualität",
		keywords: ["Moritz", "Moritz P", "Moritz Preuß"],
	},

	{
		page: "about",
		description:
			//"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
			"Firmware Entwicklung, Software Tests und Architektur Erstellung mit konstant hoher Qualität",
		keywords: ["Moritz", "Moritz P", "Moritz Preuß"],
	},

	{
		page: "articles",
		description:
			"Sammlung von Gedanken in Textform zu den Themen Programmieren, Software Architektur, Produktentwicklung und mehr.",
		keywords: ["Moritz", "Moritz P", "Moritz Preuß"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Moritz", "Moritz P", "Moritz Preuß"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Moritz", "Moritz P", "Moritz Preuß"],
	},
];

export default SEO;
