import React from "react";
import Highlight from 'react-highlight'
//import '../../../node_modules/highlight.js/styles/atom-one-light.css'
import './styles/atom-one-light-custom.css'

import "../../pages/styles/readArticle.css";


import project_structure from './project_structure.png';
import stmcubemx from './stmcubemx.png';
import protected_sections from './protected_sections_stmcode.png';

const article1 = (
<React.Fragment>
<p>
Die Entwicklung von Embedded Software ist eine komplexe Aufgabe, die
vom Entwickler ein tiefgreifendes Verständnis der verwendeten Hardware erfordert.

Die STM32 Microcontroller-Serie bietet eine Vielzahl von Hilfsprogrammen, 
darunter die Software STM32CubeMX, welche die Hardware-Konfiguration erleichtern kann.
Mit diesem Tool können Nutzer initiale Hardware-Einstellungen in einer GUI vornehmen 
und den entsprechenden Code generieren lassen.

Nach der Codegenerierung können Entwickler ihren eigenen Code an den dafür 
vorgesehenen Stellen integrieren.
Dieser bliebt dann bei Neukonfiguration der Hardware und erneuten Codegenerierung erhalten. 

<br/>
<br/>
<img src={protected_sections} alt="Sektionen für Nutzercode" width={500}/>
<br/>
<br/>

Programme wie STM32CubeMX können die Entwicklung gerade zum Start
eines neuen Projekts erleichtern und den Zeitaufwand für ein Proof of Concept reduzieren.
Zudem ist die grafische Ansicht der Hardware-Konfiguration leicht verständlich und
ermöglicht es anderen Entwicklern schnell einen Überblick über die genutzten Hardware Funktionen zu erhalten. 
<br/>
Allerdings hat die Nutzung solcher Werkzeuge und das damit verbundene 
Vermischen von generiertem Code und selbst geschriebenem Code nicht nur Vorteile.
<br/>
<br/>
Die größten Nachteile sind auch meiner Sicht die Folgenden:
<ul>
<li>Es ist oft nicht klar erkennbar, welcher Code generiert und 
    welcher manuell hinzugefügt wurde.</li>
<li>Entwickler integrieren ihren Code in die vorgegebene Projektstruktur und
    schränken sich dadurch in ihrer Flexibilität/Kreativität ein.</li>
<li>Enge Bindung an eine Hardware-Plattform liegt u.U. sehr nah und schränkt die Möglichkeiten 
    zum Nutzen und Testen der Software auf anderer Hardware ein.</li>
<li>Teile der STM32 Hardware Abstraction Layer (HAL) nutzen Compiler Features, 
    die nicht Teil des C oder C++ Sprachstandards sind was unseren Code,
    bei enger Bindung, weniger portable macht.</li>
</ul>
Um die Flexibilität in Bezug auf die Projektstruktur und die verwendete Hardware zu verbessern, 
möchte ich einen einfachen Ansatz vorstellen, der die oben genannten Probleme reduzieren kann.

Hierbei bleibt die Möglichkeit der Codegenerierung mit Hilfsprogrammen wie STM32CubeMX erhalten, während
die Integration von selbst geschriebenem in generierten Quelldateien auf ein Minimum reduziert wird.
<br/>
<br/>
<h3>Vorgehensweise:</h3>
<ol>
<li>Generieren von Code mit STM32CubeMX nicht im Hauptverzeichnis, sondern im Unterverzeichnis <i>platform/hardware_xy</i>.</li>
<li>Erstellen einer Bibliothek aus generiertem Code (mit CMake).</li>
<li>Fallunterscheidung der erstellte Bibliothek in Abhängigkeit von CMake-Variable. </li>
</ol>
<br/>

<h3>1. Generiere Code in Unterordner:</h3>
Anstatt den Code im Hauptverzeichnis zu generieren, erstellen wir einen Unterordner <i>platform</i>,
in dem wir unterschiedliche Hardware-Plattformen verwalten können.
Der <i>platform</i>-Ordner enthält hierfür mehrere Unterordner für verschiedene Mikrocontroller und Host: 
<br/>
<br/>
<img src={project_structure} alt="Projekt Struktur" width={300}/>
<br/>
<br/>

Jeder Unterordner (mit Ausnahme <i>host</i> enthält eine .ioc Datei), welche die Einstellungen für die Codegenerierung enthält
und mit STM32CubeMX geöffnet werden kann.

<br/>
<br/>
<img src={stmcubemx} alt="STM32CubeMX Project Manager" width={600} style={{border: "1px solid black"}}/>
<br/>
<br/>

Wir konfigurieren unsere Hardware wie gewünscht.
Unter Project Manager wählen wir den Ordner <i>platform/board</i> als Output-Ordner aus,
setzen IDE/Toolchain Option auf Makefile und aktivieren die Checkbox "do not generate the main()".
Nach dem Speichern der Änderungen generieren wir den Code durch einen Klick auf "Generate Code".
<br/>
<br/>
<h3>2. Erstelle Bibliothek aus generiertem Code:</h3>
In dem Ordner in dem wir den Code soeben generiert habe (z.B. <i>platform/stm32l4</i>) 
fügen wir eine CMakeLists.txt-Datei hinzu,
die die entsprechenden Compiler-Flags und die zu kompilierenden Dateien festlegt.
Einen guten Anhaltspunkt für notwendige Compiler-Flags der vorliegenden Hardware 
bietet das automatisch generierte Makefile, welches durch die genannte 
CMakeLists.txt-Datei ersetzt wird.
<br/>
Die CMakeLists Datei für STM32L4 sieht wie folgt aus:
<Highlight className='cmake'>
    {`# platform/stm32l4/CMakeLists.txt

set(CMAKE_VERBOSE_MAKEFILE ON)

# Setup project, output and linker file
project(STM32L4_BSP)
set(EXECUTABLE \${PROJECT_NAME})
set(LINKER_FILE \${CMAKE_CURRENT_SOURCE_DIR}/STM32L452RETx_FLASH.ld)

enable_language(CXX C ASM)

message(STATUS "CMAKE_TOOLCHAIN_FILE: \${CMAKE_TOOLCHAIN_FILE}")

# List of source files
set(SRC_FILES
    startup_stm32l452xx.s
    syscalls.c
    Core/Src/main.c 
    Core/Src/gpio.c 
    Core/Src/stm32l4xx_it.c 
    Core/Src/stm32l4xx_hal_msp.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_tim.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_tim_ex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_rcc.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_rcc_ex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_flash.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_flash_ex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_flash_ramfunc.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_gpio.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_dma.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_dma_ex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_pwr.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_pwr_ex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_cortex.c 
    Drivers/STM32L4xx_HAL_Driver/Src/stm32l4xx_hal_exti.c 
    Core/Src/system_stm32l4xx.c
    )

# Build the executable based on the source files
set(BINARY_NAME \${PROJECT_NAME}_LIB)
add_library(\${BINARY_NAME} STATIC \${SRC_FILES})

# List of compiler defines, prefix with -D compiler option
target_compile_definitions(\${BINARY_NAME} PUBLIC
        -DUSE_HAL_DRIVER
        -DSTM32L452xx
        )

# List of includ directories
target_include_directories(\${BINARY_NAME} PUBLIC
        \${CMAKE_CURRENT_SOURCE_DIR}
        \${CMAKE_CURRENT_SOURCE_DIR}/Core/Inc 
        \${CMAKE_CURRENT_SOURCE_DIR}/Drivers/STM32L4xx_HAL_Driver/Inc 
        \${CMAKE_CURRENT_SOURCE_DIR}/Drivers/STM32L4xx_HAL_Driver/Inc/Legacy 
        \${CMAKE_CURRENT_SOURCE_DIR}/Drivers/CMSIS/Device/ST/STM32L4xx/Include 
        \${CMAKE_CURRENT_SOURCE_DIR}/Drivers/CMSIS/Include
        )

# Compiler options
target_compile_options(\${BINARY_NAME} PUBLIC
        -mcpu=cortex-m4     # Target processor architecture
        -mthumb             # Thumb instruction set
        -mfpu=fpv4-sp-d16   # Floating point unit
        -mfloat-abi=hard    # Use hardware floating point unit

        -fdata-sections     # Place each data item into its own section
        -ffunction-sections # Place each function into its own section

        -Wall               # Enable all warnings
        )

# Linker options
target_link_options(\${BINARY_NAME} PUBLIC
        -T\${LINKER_FILE}
        -mcpu=cortex-m4
        -mthumb
        -mfpu=fpv4-sp-d16
        -mfloat-abi=hard
        -specs=nano.specs   # Use the nano.specs from the GCC source code to link with the C library (libc) for embedded systems (nano.specs is a linker script) 
        -lc                 # Use the C library (libc)
        -lm                 # Use the math library (libm)
        -Wl,-Map=\${PROJECT_NAME}.map,--cref
        -Wl,--gc-sections
        -Xlinker -print-memory-usage -Xlinker
        )
    `}
</Highlight>
Werden weitere Peripheriegeräte für STM32L4 über STM32CubeMX hinzugefügt,
müssen die entsprechenden Dateien in die CMakeLists.txt-Datei aufgenommen werden.
<br/>
<br/>
<h3>3. Fallunterscheidung für genutzte Hardware:</h3>
Durch eine einfache Fallunterscheidung in der CMakeLists.txt Datei im <i>platform</i>-Ordner 
stellen wir sicher, dass die richtige Bibliothek ausgewählt wird, 
und linken sie mit der neu erstellten <i>platform</i>-Bibliothek.
<br/>

<Highlight className='cmake'>
	{`# platform/CMakeLists.txt
add_library(platform platform.cpp platform.h)
target_include_directories(platform PUBLIC \${CMAKE_CURRENT_SOURCE_DIR})

if(HW_PLATFORM STREQUAL "STM32L4")
    message(STATUS "Building for STM32L4")
    add_subdirectory(stm32l4)
    target_link_libraries(platform PUBLIC STM32L4_BSP_LIB)
elseif(HW_PLATFORM STREQUAL "STM32F4")
    message(STATUS "Building for STM32F4")
    add_subdirectory(stm32f4)
    target_link_libraries(platform PUBLIC STM32F4_BSP_LIB)
else(HW_PLATFORM STREQUAL "HOST")
    message(STATUS "Building for Host")
    add_subdirectory(host)
endif()
`}
</Highlight>

Die <i>platform.h</i>-Datei der <i>platform-Bibliothek</i> definiert zusätzliche applikationsnahe Bezeichnungen der verwendeten
Peripheriegeräte, um die Abhängigkeit von der Hardware zu reduzieren.
Das folgende Beispiel zeigt was hier genau gemeint ist.

<Highlight className='c'>
	{`// platform/platform.h
#if HW_PLATFORM == STM32L4

#include "stm32l4/Core/Inc/main.h"

#define DEBUG_UART huart1

#elif HW_PLATFORM == STM32F4

#include "stm32f4/Core/Inc/main.h"

#define DEBUG_UART huart2
#endif /* HW_PLATFORM */
`}
</Highlight>

Die <i>platform.h</i>-Datei dient somit als zentrale Stelle,
um Unterschiede zwischen den verschiedenen Hardware-Plattformen zu verstecken.

Dies ist selbstverständlich nur bis zu einem gewissen Grad möglich und
setzt voraus, dass verwendete Mikrocontroller eine Schnittmenge an 
ähnlicher Peripherie besitzen.

Damit die Hardwareauswahl wie gewünscht funktioniert, wird ein CMake-Cache Variable (hier: HW_PLATFORM) 
definiert, die den Namen der Hardware-Plattform enthält und während der CMake-Konfiguration
festgelegt wird.
<Highlight className='cmake'>
	{`# PlatformOptions.cmake
# Configure hardware platform
set(HW_PLATFORM "STM32L4" CACHE STRING "Select platform to use for compilation")
set_property(CACHE HW_PLATFORM PROPERTY STRINGS STM32L4 STM32F4 HOST)
`}
</Highlight>
In der Haupt-CMakeLists.txt-Datei fügen wir den <i>platform</i>-Ordner 
über den Befehl <i>add_subdirectory</i> 
hinzu und linken die neue Bibliothek mit unserem restlichen Code.

<Highlight className='cmake'>
	{`# CMakeLists.txt
add_subdirectory(platform)

set (PROJECT_SOURCES main.cpp)

set(EXECUTABLE \${PROJECT_NAME})
add_executable(\${EXECUTABLE} \${PROJECT_SOURCES})
target_link_libraries(\${EXECUTABLE} PRIVATE platform)
`}
</Highlight>

Wir sind nun frei eine Projektstruktur zu wählen, die uns am besten gefällt und bei der wir
unsere Business-Logik getrennt von der Hardware implementieren können.
Anstatt unseren Code in die generierten Dateien einzufügen, linken wir hardwareabhängigen generierten Code zu unserer Software.
Wir betrachten die Hardware als das, was sie sein sollte: ein Implementierungsdetail, von dem unseren eigentliche
Business-Logik bzw. der stabilste Teil unserer Software unabhängig ist.
Damit dies funktioniert, müssen wir uns nun Gedanke über sinnvolle Schnittstellen machen, 
ein Thema, auf das ich in einem späteren Artikel eingehen werde.

Details zur beschriebenen Struktur und den zugehörigen 
Dateien findest du im <a href="https://github.com/moleop/CMakeMCUStarter" target="_blank">CMakeMCUStarter</a> GitHub-Repository.
<br/>
Das genannte Projekt enthält zudem CMake Presets für unterschiedliche Compiler (GCC, Clang, GCC Arm Embedded) sowie GoogleTest und Doxygen,
um schnell in die Entwicklung mit STM32 Mikrocontroller starten zu können und Software in großen Teilen auf dem Host ausführen und testen zu können.
</p>

</React.Fragment>
);

export default article1;
